import React from 'react'
import "./Loader.css";

function Loader() {
  return (
    <div className='loading'>
        <div></div>
    </div>
  )
}

export default Loader