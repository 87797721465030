import React, { useState, useEffect } from 'react'
import "./ForgotPassword.css"
import Loader from '../layout/Loader/Loader'
import MetaData from '../layout/MetaData';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useAlert } from 'react-alert';

import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../../actions/userAction";


function ForgotPassword() {

    const dispatch = useDispatch();
    const alert = useAlert();

    const { error, message, loading } = useSelector((state) => state.forgotPassword);

    const [email, setEmail] = useState("");

    const forgotPasswordSubmit = (e) => {
        // e.preventDefault();

        const myForm = new FormData();

        myForm.set("email", email);
        dispatch(forgotPassword(myForm));
    };

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (message) {
            alert.success(message);

        }
    }, [dispatch, error, alert, message])

  return (
    <>
        {loading ? <Loader/> : (
            <>
            <MetaData title="Forgot Password" />
            <div className="forgotPasswordContainer">
                <div className="forgotPasswordBox">
                    <h2 className='forgotPasswordHeading'>Forgot Password</h2>
                    <form
                        className="forgotPasswordForm"
                        onSubmit={forgotPasswordSubmit}
                    >
                        <div className="forgotPasswordEmail">
                            <MailOutlineIcon />
                            <input
                                type="email"
                                placeholder="Email"
                                required
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <input type="submit" value="send" className="forgotPasswordBtn" />
                    </form>
                </div>
            </div>
        </>
        )}
        </>
  )
}

export default ForgotPassword