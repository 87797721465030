import React from 'react'
import "./About.css"
import logo from "../../../images/logo.png"

function About() {
  return (
    <div className='aboutContainer'>
        <img src={logo} alt="MyDukan.com" />
    </div>
  )
}

export default About