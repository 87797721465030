import React from 'react';
import { Link } from "react-router-dom";
import {Rating} from "@material-ui/lab";


function ProductCard({product}) {
  
  const options = {
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
    size: "small",
    
};

  return (
    <Link className="productCard" to={`/product/${product?._id}`}>
      <div className="imageContainer">

      <img src={product?.images[0]?.url} alt={product?.name} />
      </div>
      <p>{product.name}</p>
      <div>
        <Rating {...options} />{" "}
        <span className="productCardSpan">
          {" "}
          ({product.numOfReviews} Reviews)
        </span>
      </div>
      <span>{`₹${product.price}`}</span>
    </Link>
  )
}

export default ProductCard