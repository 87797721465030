import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { 
    newProductReducer, 
    newReviewReducer, 
    productDetailsReducer, 
    productReducer, 
    productReviewsReducer, 
    productsReducer, 
    reviewReducer 
} from "./reducers/productReducer";
import { allUsersReducer, 
    forgotPaswordReducer, 
    profileReducer, 
    userDetailsReducer, 
    userReducer 
} from "./reducers/userReducer";
import { cartReducer } from "./reducers/cartReducer";
import { 
    allOrdersReducer, 
    myOrdersReducer, 
    newOrderReducer, 
    orderDetailsReducer, 
    orderReducer 
} from "./reducers/orderReducer";
const reducer = combineReducers({
    products: productsReducer,
    productDetails: productDetailsReducer,
    user: userReducer,
    profile: profileReducer,
    forgotPassword: forgotPaswordReducer,
    cart: cartReducer,
    newOrder: newOrderReducer,
    myOrders: myOrdersReducer,
    orderDetails: orderDetailsReducer,
    newReview: newReviewReducer,
    newProduct: newProductReducer,
    product: productReducer,
    allOrders: allOrdersReducer,
    order: orderReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,
    productReviews: productReviewsReducer,
    review: reviewReducer,
});

let initalState = {
    cart: {
        cartItems: localStorage.getItem("cartItems")
            ? JSON.parse(localStorage.getItem("cartItems"))
            : [],
        shippingInfo: localStorage.getItem("shippingInfo")
            ? JSON.parse(localStorage.getItem("shippingInfo"))
            : {},
    },
};

const middleware = [thunk];

const store = createStore(
    reducer, 
    initalState, 
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;